/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@font-face {
    font-family: 'regular';
    src: url('./assets/fonts/Poppins-Regular.ttf');
}

@font-face {
    font-family: 'medium';
    src: url('./assets/fonts/Poppins-Medium.ttf');
}

@font-face {
    font-family: 'semi-bold';
    src: url('./assets/fonts/Poppins-SemiBold.ttf');
}

@font-face {
    font-family: 'bold';
    src: url('./assets/fonts/Poppins-Bold.ttf');
}

* {
    font-family: 'regular';
}


/** Ionic CSS Variables **/
:root {
    /** primary **/
    --ion-color-primary: #df2077;
    --ion-color-primary-rgb: 56, 128, 255;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255, 255, 255;
    --ion-color-primary-shade: #3171e0;
    --ion-color-primary-tint: #4c8dff;
  
    /** secondary **/
    --ion-color-secondary: #890e67;
    --ion-color-secondary-rgb: 61, 194, 255;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255, 255, 255;
    --ion-color-secondary-shade: #36abe0;
    --ion-color-secondary-tint: #50c8ff;
  
    /** tertiary **/
    --ion-color-tertiary: #5260ff;
    --ion-color-tertiary-rgb: 82, 96, 255;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255, 255, 255;
    --ion-color-tertiary-shade: #4854e0;
    --ion-color-tertiary-tint: #6370ff;
  
    /** success **/
    --ion-color-success: #2dd36f;
    --ion-color-success-rgb: 45, 211, 111;
    --ion-color-success-contrast: #ffffff;
    --ion-color-success-contrast-rgb: 255, 255, 255;
    --ion-color-success-shade: #28ba62;
    --ion-color-success-tint: #42d77d;
  
    /** warning **/
    --ion-color-warning: #ffc409;
    --ion-color-warning-rgb: 255, 196, 9;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0, 0, 0;
    --ion-color-warning-shade: #e0ac08;
    --ion-color-warning-tint: #ffca22;
  
    /** danger **/
    --ion-color-danger: #eb445a;
    --ion-color-danger-rgb: 235, 68, 90;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #cf3c4f;
    --ion-color-danger-tint: #ed576b;
  
    /** dark **/
    --ion-color-dark: #222428;
    --ion-color-dark-rgb: 34, 36, 40;
    --ion-color-dark-contrast: #ffffff;
    --ion-color-dark-contrast-rgb: 255, 255, 255;
    --ion-color-dark-shade: #1e2023;
    --ion-color-dark-tint: #383a3e;
  
    /** medium **/
    --ion-color-medium: #92949c;
    --ion-color-medium-rgb: 146, 148, 156;
    --ion-color-medium-contrast: #ffffff;
    --ion-color-medium-contrast-rgb: 255, 255, 255;
    --ion-color-medium-shade: #808289;
    --ion-color-medium-tint: #9d9fa6;
  
    /** light **/
    --ion-color-light: #f4f5f8;
    --ion-color-light-rgb: 244, 245, 248;
    --ion-color-light-contrast: #000000;
    --ion-color-light-contrast-rgb: 0, 0, 0;
    --ion-color-light-shade: #d7d8da;
    --ion-color-light-tint: #f5f6f9;
  }
  
 
 

ion-label {
    display: block;
    --font-family: 'regular';
    font-family: 'regular';
}

ion-button {
    margin: 0px;
    font-family: 'medium';
    letter-spacing: 1px;
    --border-radius: 10px;
    font-size: 16px;
    height: 48px;
}

ion-input, ion-select {
    border-radius: 10px;
    border: 1px solid lightgrey;
    height: 48px;
    --padding-start: 10px !important;
    font-family: 'regular' !important;
}


.forgot_lbl {
    text-align: right;
    margin-bottom: 30px;

    span {
        color: var(--ion-color-primary);
    }
}



.title_lbl {
    font-family: 'bold';
    font-size: 30px;
    margin-bottom: 20px;
}

.sub_title_lbl {
    color: grey;
    margin-bottom: 24px;
}

ion-title {
    font-weight: normal;
    font-family: 'medium';
    letter-spacing: 0.5px;
    font-size: 17px;
}


ion-modal.choose_product {
    --height: 100%;
    align-items: flex-end;

    .modal-wrapper {
        width: 100% !important;
        //border-top-left-radius: 5px;
        //border-top-right-radius: 5px;
        //box-shadow: 0 -4px 5px -4px rgba(0,0,0,0.4);
    }
    &.bottom {
        align-items: flex-end !important;
    }
}

.back_image {
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    position: relative !important;
}

.product_slider {
    display: flex;
    width: 100%;
    overflow: scroll;
    margin-bottom: 16px;

    .product_div {
        margin-right: 16px;

        .image_box {
            width: 120px;
            //height: 110px;
            height: 80px;
            border-radius: 16px;
            background: lightgrey;
            margin-bottom: 5px;
        }

        .grey_lbl {
            color: grey;
            font-size: 13px;
        }

        .name_lbl {
            font-family: 'medium';
            font-size: 14px;
        }
    }
}

.left {
    padding-left: 8px !important;
}

.right {
    padding-right: 8px !important;
}


.grid_product_div {
    margin-bottom: 20px;

    .image_box {
        width: 100%;
        height: 170px;
        border-radius: 16px;
        background: #f3f3f3;

        .like_div {
            height: 34px;
            width: 34px;
            border-radius: 10px;
            // background: lightgrey;
            background: rgba(0,0,0,0.2);
            position: absolute;
            top: 5px;
            right: 5px;
            display: flex;
            align-items: center;
            justify-content: center;

            ion-icon {
                color: red;
                font-size: 18px;
            }
        }
    }
    .pro_info {
        margin-top: 10px;
        .grey_lbl {
            color: grey;
            font-size: 14px;
        }
        .pro_name {
            font-family: 'medium';
            font-size: 16px;
        }
        .pro_price {
            font-family: 'medium';
            margin-bottom: 5px;
        }

        .pro_flex {
            display: flex;

            div {
                display: flex;
                align-items: center;
                margin-right: 16px;

                ion-icon {
                    color: var(--ion-color-primary);
                }
                ion-label {
                    margin-left: 8px;
                    font-size: 14px;
                    font-family: 'medium';
                }
            }
        }
    }
}

.single_post {
   // background: white;
    padding: 16px;
    margin-bottom: 12px;
    border-bottom: 8px solid rgb(219, 217, 217);

  .user_div {
      display: flex;
      margin-bottom: 10px;
      position: relative;

      .user_image {
          width: 46px;
          height: 46px;
          min-width: 46px;
          background: lightgray;
          border-radius: 100%;
      }

      .user_detail {
          margin-left: 12px;

          .name_lbl {
              font-family: 'medium';
              font-size: 15px;
              margin-bottom: 2px;
          }

          .grey_lbl {
              color: grey;
              font-size: 12px;
          }
      }

      .more_icn {
          position: absolute;
          right: 0;
          font-size: 20px;
      }
  }

  .about_lbl {
      font-size: 14px;
      margin-bottom: 10px;
  }

  .image_box {
      width: 100%;
      height: 370px;
      border-radius: 20px;
      background: lightgrey;
  }

  .action_div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 10px;

      .like_div {
          display: flex;

          div {
              display: flex;
              margin-right: 16px;

              ion-icon {
                  font-size: 24px;
                  color: grey;
              }

              img {
                  padding-bottom: 2px;
              }

              ion-label {
                  font-family: 'medium';
                  margin-left: 10px;
              }
          }

          .share_lbls {
              font-size: 13px;
          }

          .share_lbl {
            margin-left: 15px;
            font-size: 13px;
        }
      }

      .bookmark_flex {
          ion-icon {
              color: grey;
              font-size: 24px;
              margin-left: 20px;
          }
      }
  }
}





.scroll-content{
    overflow-y:auto!important;
    -ms-overflow-style: none;
  
    &::-webkit-scrollbar {
        display: none;
    }
}

  *[scrollX=true] {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    -ms-overflow-style: none;
  
    &::-webkit-scrollbar {
        display: none;
    }
  
    .scrollX {
        flex: 0 0 auto;
    }
  }

  .popover_setting {
    --ion-backdrop-opacity: 0.1; 
	--box-shadow: 80px;
    border-radius: 20px;
    --border-radius: 20px;
    .popover-arrow {
        display: none;
    }
    
    .popover-content {
		border-radius: 20px;
        --border-radius: 20px;
        //left: 10px !important;
        width: calc(100% - 50px);
    }
}

ion-popover::part(content) {
    border-radius: 20px;
    width: calc(100% - 80px);
  }

  
